<template>
  <div class="px-5 text-center">
    <div class="mb-10">
      <IcoContractRejected type="error" />
    </div>

    <v-card class="mx-auto mb-10" :elevation="elevation" max-width="45rem">
      <v-card-title>
        <div class="static__layout--title display-1 mx-auto mt-5">
          {{ $t('contract.expired_link') }}
        </div>
      </v-card-title>

      <v-card-text>
        <p v-if="!isApitokenOutdated && !isApitokenExpired" class="subtitle-1 my-5">
          {{ $t('contract.incorrect_pairing_hash') }}
        </p>

        <p v-if="isApitokenOutdated" class="subtitle-1 my-5">
          {{ $t('contract.contract_being_modified') }}
        </p>

        <p v-if="isApitokenExpired" class="subtitle-1 my-5">
          {{ $t('general.link_has_expired') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue'

export default {
  name: 'ContractExpiredLink',
  components: {
    IcoContractRejected
  },
  props: {
    isApitokenOutdated: {
      type: Boolean,
      default: false
    },
    isApitokenExpired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    elevation () {
      return this.$vuetify.breakpoint.mobile ? 0 : 2
    }
  }
}
</script>

<style lang="scss" scoped>
.static__layout--title {
  word-break: break-word;
}
</style>
